// https://daveceddia.com/multiple-environments-with-react/

const HOSTNAME = window && window.location && window.location.hostname;

let backendUri;
if (HOSTNAME === 'perform.southbranchcontrols.com') {
	backendUri = 'https://perform.southbranchcontrols.com';
} else if (HOSTNAME === 'beta.southbranchcontrols.com') {
	backendUri = 'https://beta.southbranchcontrols.com';
} else {
	backendUri = 'http://localhost:8090';
}

export const ANIMATION_DURATION = 20;
export const API_URL = backendUri;
export const BOARD_REFRESH = 2;
export const EMAIL_PATTERN = /[a-z0-9!#$%&'*+\\/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+\\/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9][a-z0-9-]*[a-z0-9]/;
// This is a negative test (matching what is not in the list)
export const PHONE_PATTERN = /[^-+.()0-9 ]/;

export const ALERT_DISABLED_ICON='bell slash outline';
export const ALERT_ENABLED_ICON='bell';
export const BOARD_ICON='table';
export const BOARDS_ICON='bars';
export const CANCEL_ICON='cancel';
export const CONFIG_ICON='cogs';
export const DELETE_ICON='trash';
export const DEVICE_ICON='plug';
export const DEVICE_READ_ONLY_ICON='eye';
export const HOME_ICON='home';
export const METRIC_ICON='play circle outline';
export const SAVE_ICON='check';
export const SERIES_ICON='line graph';
export const STATION_ICON='wifi';
export const TENANT_ICON='building';
export const UPLOAD_ICON='upload';
export const USER_ICON='user';
