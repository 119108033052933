import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {authService, lookupService} from './Api'
import {Button, Form, Grid, Input, Message, Segment} from 'semantic-ui-react'
import logo from './logo-white.svg'
import manifest from './manifest.json';

export class Login extends Component {

	state = {
		username: '',
		password: '',
		message: '',
		client: {name: '', version: '', timestamp: ''},
		server: {name: '', version: '', timestamp: ''},
		redirectToReferrer: false
	};

	componentDidMount() {
		this.loadClientInformation();
		this.loadServerInformation();
	}

	formatDate = (date) => {
		const isoDate = date.toISOString();
		const dateParts = isoDate.split('T');
		const isoTime = dateParts[1].split('.');
		return dateParts[0] + ' ' + isoTime[0];
	}

	loadClientInformation = () => {
		const version = manifest.version;
		const timestamp = this.formatDate(new Date(manifest.timestamp));
		this.setState({client: {version: version, timestamp: timestamp}});
	}

	loadServerInformation = () => {
		lookupService.getProgramInformation((server) => {
			this.setState({server: server})
		}, (message) => {
			console.log(message);
		});
	};

	login = () => {
		// This line helps the user know there is something happening
		this.setState({message: ''});
		authService.login(this.state.username, this.state.password, () => {
			this.setState({message: '', redirectToReferrer: true});
		}, (message) => {
			this.setState({message: 'Login failure'})
		});
	};

	handleUsernameChange = (event) => {
		this.setState({username: event.target.value});
	};

	handlePasswordChange = (event) => {
		this.setState({password: event.target.value});
	};

	render() {
		const message = this.state.message;
		const {from} = {from: {pathname: '/home'}};

		if (this.state.redirectToReferrer) return <Redirect to={from}/>;

		return (
			<div id='login'>
				<style>{`body > div, body > div > div, body > div > div > div { height: 100%; }`}</style>
				<Grid centered verticalAlign='middle' style={{height: '80%'}}>
					<Grid.Row columns={1}>
						<Grid.Column style={{maxWidth: 450}}>
							<Segment inverted color='blue'>
								<div className="login-header">
									<img className="login-logo" src={logo} alt="logo"/>
									<span className="login-title">Perform</span>
								</div>

								<Form error={message !== ''}>
									<Form.Field control={Input} id="username" autoComplete="username" fluid icon='user' iconPosition='left' placeholder='Username or Email Address' onChange={this.handleUsernameChange}/>
									<Form.Field control={Input} id="password" autoComplete="current-password" fluid icon='lock' iconPosition='left' placeholder='Password' type='password' onChange={this.handlePasswordChange}/>
									<Message error header={message}/>
									<Button fluid size='large' onClick={this.login}> Login </Button>
								</Form>
							</Segment>
							<Grid columns={3} padded={'horizontally'}>
								<Grid.Row>
									<Grid.Column width={4}>
										Client:<br/>Server:
									</Grid.Column>
									<Grid.Column width={4} textAlign={'right'}>
										{this.state.client.version}<br/>{this.state.server.version}
									</Grid.Column>
									<Grid.Column width={8} textAlign={'right'}>
										{this.state.client.timestamp}<br/>{this.state.server.timestamp}
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		);

	}

}
