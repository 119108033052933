import React from 'react';
import {Form, Grid, Header, Icon, Segment} from 'semantic-ui-react'
import {metricService} from "../Api";

const DeviceMetricEditorType = {
	NONE: 'NONE',
	FIELD: 'FIELD',
	SELECT: 'SELECT',
};

export default class DeviceMetric extends React.Component {

	state = {
		editing: false
	};

	handleStartEditing = () => {
		if (this.props.metric.editable) this.setState({editing: true})
	};

	handleStopEditing = () => {
		this.setState({editing: false})
	};

	handleSaveValue = (metric) => {
		this.handleStopEditing();
		metricService.updateMetricValue(metric, () => {
			this.props.onRefreshBoard()
		}, () => {
			// failure
			console.log("That didn't go well!")
		});
	};

	handleCancelEdit = () => {
		this.handleStopEditing()
	};

	render() {
		let icon = this.props.metric.editable ? 'play' : '';
		icon = this.props.metric.online ? icon : 'pause';
		let iconColor = this.props.metric.online ? 'green' : 'red';

		let element = <Header>{this.props.metric.value}</Header>;
		if (this.state.editing) element = <MetricEditor metric={this.props.metric} onSaveValue={this.handleSaveValue} onCancelEdit={this.handleCancelEdit}/>;

		return (
			<Segment>
				<Grid>
					<Grid.Row verticalAlign='middle' onClick={this.handleStartEditing}>
						<Grid.Column width={8}><Icon name={icon} color={iconColor}/>{this.props.metric.name}</Grid.Column>
						<Grid.Column width={6} textAlign='right'>{element}</Grid.Column>
						<Grid.Column width={2} textAlign='left'><Header color={'grey'}>{this.props.metric.unit}</Header></Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		)
	}

}

class MetricEditor extends React.Component {

	render() {
		if (this.props.metric.editor === DeviceMetricEditorType.FIELD) {
			return <NumberMetricEditor metric={this.props.metric} onSaveValue={this.props.onSaveValue} onCancelEdit={this.props.onCancelEdit}/>;
		} else if (this.props.metric.editor === DeviceMetricEditorType.SELECT) {
			return <SelectMetricEditor metric={this.props.metric} onSaveValue={this.props.onSaveValue} onCancelEdit={this.props.onCancelEdit}/>;
		} else {
			return <div/>
		}
	}

}

class NumberMetricEditor extends React.Component {

	state = {
		key: this.props.metric.id,
		value: this.props.metric.value,
	};

	constructor(props) {
		super(props);
		this.editorRef = React.createRef();
	}

	componentDidMount() {
		this.editorRef.current.focus();
		this.editorRef.current.select();
	}

	handleSaveValue = () => {
		this.props.onSaveValue({
			id: this.state.key,
			value: this.state.value,
		})
	};

	handleCancelEdit = () => {
		this.props.onCancelEdit()
	};

	handleFieldChange = (event) => {
		this.setState({[event.target.name]: event.target.value})
	};

	handleKeyDown = (event) => {
		if (event.key === 'Enter') this.handleSaveValue();
		if (event.key === 'Escape') this.handleCancelEdit();
	};

	render() {
		return (
			<Form.Field control={Form.Input} name='value' value={this.state.value} onChange={this.handleFieldChange} onKeyDown={this.handleKeyDown}
									onBlur={this.handleCancelEdit}>
				<input ref={this.editorRef}/>
			</Form.Field>
		)
	}

}

class SelectMetricEditor extends React.Component {

	state = {
		options: []
	};

	componentDidMount() {
		const options = this.props.metric.options.map((option) => {
			return {'key': option, 'text': option, 'value': option}
		});
		this.setState({options: options});
	}

	handleSelectChange = (event, {name, value}) => {
		this.props.onSaveValue({
			id: this.props.metric.id,
			value: value,
		})
	};

	render() {
		return (
			<Form.Select name='value' defaultOpen closeOnBlur={true} value={this.props.metric.value} options={this.state.options} onChange={this.handleSelectChange}
									 onClose={this.props.onCancelEdit}/>
		)
	}

}
